/**
 * Varatut kenttien nimet.
 * @type {{CONTRACT_TYPE: string, FIXED_TERM_REASON: string, EMPLOYER: string, PAY_PERIOD_LENGTH: string, EMPLOYEE: string, SALARY_TYPE: string, JOB_TITLE: string, COLLECTIVE_AGREEMENT: string, PAY_DAY_MODE: string, PAY_DAY: string}}
 */
export const fieldNames = {
    TYPE: 'type', // Hieno geneerinen nimi joka ei kerro mitään. Käytännössä siis JobContractType.
    CONTRACT: 'jobContract',
    CONTRACT_DATA: 'jobContractData',
    CONTRACT_STATE: 'jobContractState',

    // Osapuolet
    EMPLOYER: 'employer',
    EMPLOYEE: 'employees',
    CARED: 'cared',

    // Lähisukulaisuus
    IS_EMPLOYEE_RELATED: 'employeeRelated',
    // Lomien kerrytys
    IS_HOLIDAY_GENERATION_ENFORCED: 'enforceHolidayGeneration',

    // Työtehtävä ja TES
    JOB_TITLE: 'jobTitle',

    // Työtehtävän sijainti
    JOB_TARGET_ADDRESS: 'jobTargetAddress',
    COLLECTIVE_AGREEMENT: 'collectiveAgreement',
    COLLECTIVE_AGREEMENT_VARIABLES: 'collectiveAgreementVariables',
    //Tehtävän kuvaus
    DESCRIPTION: 'description',

    CONTRACT_TYPE: 'contractType',
    FIXED_TERM_REASON: 'fixedTermReason',
    FIXED_TERM_REASON_CUSTOM: 'fixedTermReasonCustom',

    // Työaika
    WORK_TIME_ESTIMATION_TYPE: 'workTimeEstimationType',
    WORK_TIME_AUTONOMY: 'workTimeAutonomy',
    HAS_WEEKLY_WORK_HOURS:'useWeeklyWorkHours',

    WEEKLY_WORK_HOURS: 'weeklyWorkHours',
    MAX_WEEKLY_WORK_HOURS: 'maxWeeklyWorkHours',

    // Dimensssssiot
    DIMENSIONS: 'dimensions',

    // Salaarit
    // ...tämä salaarien juurinimi, ei yksistään käytössä
    SALARIES: 'salaries',
    SALARY_DIMENSION_TYPE: 'dimension',
    IS_TABLE_SALARY_IN_USE: 'isTableSalaryInUse',
    IS_PENDING_SALARY: 'isPendingSalary',
    IS_PENDING_COST_REIMBURSEMENT: 'isPendingCostReimbursement',
    IS_PENDING_COST_REIMBURSEMENT_SPECIAL_ALLOWANCE: 'isPendingCostReimbursementSpecialAllowance',
    TABLE_SALARY: 'tableSalary', // Metadatan id
    TABLE_SALARY_ATTRIBUTES: 'tableSalaryAttributes',
    COST_REIMBURSEMENT_ATTRIBUTES: 'costReimbursementAttributes',
    COST_REIMBURSEMENT: 'costReimbursement',
    COST_REIMBURSEMENT_CUSTOM: 'costReimbursementCustom',
    COST_REIMBURSEMENT_SPECIAL_ALLOWANCE_ATTRIBUTES: 'costReimbursementSpecialAllowanceAttributes',
    COST_REIMBURSEMENT_SPECIAL_ALLOWANCE: 'costReimbursementSpecialAllowance',
    SALARY_TYPE: 'salaryType',
    INITIAL_SALARY: 'initialSalary',
    INITIAL_PERSONAL_SALARY: 'initialPersonalSalary',
    //Vähimmäispalkka
    MINIMUM_REQUIRED_TABLE_SALARY: 'minimumRequiredTableSalary',
    // Osa-aikaprosentti
    PART_TIME_PERCENTAGE: 'partTimePercentage',

    MIN_REQUIRED_WORK_TIME_DEFAULT: 'minRequiredWorkTimeDefault',
    MIN_REQUIRED_WORK_TIME_COST_REIMBURSEMENTS: 'minRequiredWorkTimeCostReimbursements',

    CONTRACT_GROUP_DIVISORS: 'jobContractGroupDivisors',

    PAY_PERIOD_LENGTH: 'payPeriodLength',
    PAY_DAY_MODE: 'paydayMode',
    PAY_DAY: 'payDay',
    PAYSLIP_DELIVERY_METHOD: 'payslipDeliveryMethod',
    EMPLOYER_PAYSLIP_DELIVERY_METHOD: 'employerPayslipDeliveryMethod',

    // Henkilöstöryhmä
    PERSONNEL_GROUP: 'personnelGroup',

    // Muutosten voimaantulopäivä
    DATA_START_DATE: 'dataStartDate',
    EARLIEST_DATA_START_DATE: 'earliestDataStartDate',

    // Työsuhteen alkup. aloituspäivä
    ORIGINAL_START_DATE: 'originalStartDate',

    // Kesto
    START_DATE: 'startDate',
    END_DATE: 'endDate',

    // Koeaika
    HAS_TRIAL_PERIOD: 'hasTrialPeriod',
    TRIAL_PERIOD_END_DATE: 'trialPeriodEndDate',

    // Lomat
    IS_HOLIDAY_GENERATION_APPLIED: 'isHolidayGenerationApplied',
    HOLIDAY_PAY_METHOD: 'holidayPayMethod',

    // Julkiset liitteet
    PUBLIC_ATTACHMENTS: 'publicAttachments',
    // Yksityiset liitteet
    ATTACHMENTS: 'attachments',

    // Allekirjoitukset
    SIGNING_EMPLOYER: 'signingEmployer',
    SIGNING_COMMUNE_OPERATOR: 'signingCommuneOperator',

    // Rikosrekisteriotteen esityspvm
    CRIMINAL_RECORD_DATE: 'criminalRecordDate',

    // Muuta sovittua
    MISC_MUTUAL_AGREEMENT: 'miscMutualAgreement',

    // Työsuhteen irtisanominen
    NOTICE_PERIOD_DISCLAIMER_TEXT: 'noticePeriodDisclaimerText',

    /**
     * Lomakkeet. TODO: Kategorisoi nämä kaikki paremmin.
     */
    REPORTING_FORM_SIGNATORY: 'reportingFormSignatory',
    REPORTING_FORM_SIGNING_OPERATOR: 'reportingFormsSigningOperator',
};

