import userRoles from 'shared/constants/userRoles';

export const DimensionSchema = {
    title: 'Dimensiopalikka',
    icon: 'widgets',
    description: 'Lorem ipsum',
    properties: {
        name: {
            type: 'string',
            title: 'Dimension nimi',
            description: 'Käytä objektinotaatiota: esim. dimensions.id209, jossa 209 on dimension id.',
            // Vaaditaan objekti-notaatio. Etuliite "id" pakollinen koska muutoin nämä tulkittaisiin taulukoksi.
            pattern: '^\\b(dimensions)\\b\\.\\bid([0-9]+)$'
        },
    },
    isEnabledOnRoles: [userRoles.COMPANY_OPERATOR],
};
